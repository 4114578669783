@import "./mixins";
#slider-section {
  height: 100vh;
  overflow: hidden;
  // text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::before {
    background-color: rgba(14, 8, 31, .8);
    content: " ";
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
  }
  #info-container {
    position: absolute;
    color: white;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 60px;
      @include my_bp(sm) {
        font-size: 80px;
      }
    }
  }
  img.slide {
    height: 100vh;
    width: auto;
    @include my_bp(sm) {
      height: auto;
      width: 100vw;
    }
  }
}

.button {
  padding: 14px 28px;
  text-transform: uppercase;
  line-height: initial;
  border-radius: 0;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  background-color: #ff5d24;
  border: 1px solid #ff5d24;
  &:hover {
    color: #ff5d24;
    border-color: #ff5d24;
    background-color: transparent;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
  }
}

#main-section {
  height: 100%;
  display: flex;
  padding: 10px;
  padding-top: 100px;
  flex-direction: column;
  @include my_bp(sm) {
    flex-direction: row;
  }
  @include my_bp(sm) {
    padding: 80px;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    @include my_bp(sm) {
      width: 50%;
      padding: 0;
    }
    p {
      text-align: justify;
    }
    span {
      display: inline-flex;
      color: #ff5d24;
      background-color: #ffdfd3;
      font-weight: 600;
      padding: 9px 30px;
      border-radius: 30px;
      margin-bottom: 15px;
    }
  }
  .image {
    text-align: center;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }
  .counter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .counter-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 5px;
    width: 250px;
    &:nth-of-type(1) {
      // background-color: #0a0229;
      background: radial-gradient(circle at 0px 0px, #ffa989 0%, #ff5d24 100%);
      color: white;
    }
    &:nth-of-type(2) {
      // background-color: brown;
      background: radial-gradient(circle at 0px 0px, #ff5d24 0%, #ffa989 100%);
      color: white;
    }
    .count-number {
      font-family: 'Righteous', cursive;
      font-size: 50px;
      text-align: center;
    }
    .count-text {
      font-family: 'Raleway', sans-serif;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
    .left {
      display: flex;
      flex-direction: column;
    }
  }
}

#about-section {
  height: 100%;
  display: flex;
  padding: 10px;
  // padding-top: 100px;
  flex-direction: column;
  @include my_bp(sm) {
    flex-direction: row;
  }
  @include my_bp(sm) {
    padding: 20px 80px;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    @include my_bp(sm) {
      width: 50%;
      padding: 0;
    }
    p {
      text-align: justify;
    }
    span {
      display: inline-flex;
      color: #ff5d24;
      background-color: #ffdfd3;
      font-weight: 600;
      padding: 9px 30px;
      border-radius: 30px;
      margin-bottom: 15px;
    }
    ul {
      list-style: none;
      li:before {
        content: '✓';
        margin-left: -25px;
        padding-right: 10px;
        color: #ffb923;
        font-weight: bold;
      }
    }
  }
  .image {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#who-section {
  padding: 10px;
  @include my_bp(sm) {
    padding: 50px 80px;
  }
  .single-box {
    background-color: #ffffff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    padding: 30px;
    border-radius: 3px;
    margin-bottom: 30px;
    transition: 0.5s;
    &:hover {
      transform: translateY(-5px);
      .icon {
        transform: rotateY(-180deg);
      }
    }
    .icon {
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 80px;
      background: linear-gradient(to bottom, #ffdfd4, #ffe6e0, #ffeeeb, #fff6f5, #fffefe);
      text-align: center;
      border-radius: 50%;
      color: #ff5d24;
      transition: 0.5s;
    }
    h4 {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 700;
    }
    p {
      text-align: justify;
    }
    a {
      color: #ff5d24;
      font-weight: 600;
    }
  }
}